import indexDataChart from "@/components/indexDataChart.vue";
import { getServerIndexData } from "@/api/api";
export default {
  name: "index",
  components: {
    indexDataChart
  },
  data() {
    return {
      indexInfo: []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getServerIndexData().then(res => {
        this.indexInfo = res.data;
        this.$refs.chartBarOptionData.initChart(this.indexInfo.monthActiveData, 'barOption'); //近一年的活动数据统计
        this.$refs.chartAxis1OptionData.initChart(this.indexInfo.monthData, 'axisOptions'); //近一年的订单；量、金额数据统计
        this.$refs.chartAxis2OptionData.initChart(this.indexInfo.monthData, 'axisOption'); //近一年的收益数据统计
      });
    }
  }
};